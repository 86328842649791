<ion-header class="profile-summary-header ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <!-- avatar, member name, follow button -->
  <ion-item lines="none">

    <ion-avatar class="avatar-large avatarLargeBorder" [@borderState]="this.followButton ? 'off' : 'on'" slot="start"
      style="margin-right: 16px; margin-bottom: 10px; margin-top: 0;">
      <img #imgAvatar [imgLoader]="member.avatar.URI" (click)="appFunction.showAvatarView($event, imgAvatar)"
        draggable="false">
    </ion-avatar>

    <ion-label>

      <h5>{{member.firstName}} {{member.lastName}} <span *ngIf="this.accountService.member.id === member.id">(me)</span>
      </h5>

      <p>{{member.email}}</p>

      <!-- only show if not logged in member -->
      <ng-container *ngIf="this.accountService.member.id !== member.id">

        <ion-button *ngIf="followButton" expand="block" shape="round" (click)="requestToFollowMember()"
          style="margin-top: 10px;">
          Follow
        </ion-button>

        <ion-button *ngIf="!followButton" expand="block" shape="round" (click)="unfollowMemberAction(memberFollower)"
          style="margin-top: 10px;">Unfollow
        </ion-button>

      </ng-container>

    </ion-label>

    <!-- <div slot="end" style="height: 100%; padding-top: 5px; margin-right: 0px; margin-left: 0px;" (click)="done()">
      <ion-icon name="close-circle" style="color: #cbcbcb; font-size: 30px; "></ion-icon>
    </div> -->

  </ion-item>

  <!-- three header buttons -->
  <ion-grid class="ion-no-padding" style="margin-bottom: 1px">

    <ion-row class="ion-text-nowrap ion-text-center">

      <!-- post -->
      <ion-col class="posts" (click)="profileSummarySegment='posts'"
        [class.selected]="profileSummarySegment === 'posts'">

        <p class="statisticNumber">{{member.postCount}}</p>
        <p class="statisticName">posts</p>

      </ion-col>

      <!-- followers -->
      <ion-col class="followers" (click)="profileSummarySegment='followers'"
        [class.selected]="profileSummarySegment === 'followers'">

        <p class="statisticNumber">{{member.social.followers.length}}
          <ion-badge mode="ios" color="primary"
            *ngIf="(accountService.member)?.social.requestedFollowerCount > 0 && accountService.member.id === member.id"
            style="position: absolute; top: 5px; margin-left: 5px;">
            {{(accountService.member)?.social.requestedFollowerCount}}</ion-badge>
        </p>
        <p class="statisticName">followers</p>

      </ion-col>

      <!-- following -->
      <ion-col class="following" (click)="profileSummarySegment='following'"
        [class.selected]="profileSummarySegment === 'following'">

        <p class="statisticNumber">{{following.length}}</p>
        <p class="statisticName">following</p>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-header>

<ion-content class="profile-summary-content ion-no-padding" #content>

  <!-- if the member is the logged in member, or member has a private account, or member has a private account and follower has been given permission -->
  <div
    [ngSwitch]="accountService.member.id === member.id || !member.privateAccount || (member.privateAccount && (memberFollower?.status === socialStatus.Following))">

    <div *ngSwitchCase="true">

      <!-- posts -->
      <div [hidden]="profileSummarySegment !== 'posts'">

        <ng-container *ngIf="posts?.length > 0, else noPosts">

          <ion-list>
            <ng-container *ngFor="let post of posts;">
              <post-card [post]="post" [content]="content"></post-card>
            </ng-container>
          </ion-list>

          <ion-infinite-scroll (ionInfinite)="getNextPosts($event)" threshold="1px">
            <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more posts...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>

          <ion-note *ngIf="noMorePosts" class="instructional" style="margin-bottom: 10px;">No more posts
          </ion-note>

        </ng-container>

      </div>

      <!-- followers -->
      <div [hidden]="profileSummarySegment !== 'followers'">

        <ng-container *ngIf="member.social.followers.length > 0, else noFollowers">

          <ion-list>

            <!-- requested followers -->
            <ion-item-group *ngIf="requestedFollowers.length > 0" class="itemLines">

              <!-- only show the divider for the logged in member -->
              <ion-item-divider *ngIf="accountService.member.id === member.id">Requested Members</ion-item-divider>

              <ion-item *ngFor="let requestedFollower of requestedFollowers; let i = index;">

                <avatar #avatar [member]="requestedFollower.member" slot="start"></avatar>

                <ion-label (click)="avatar.displayProfileSummary($event);">
                  <h2>{{requestedFollower.member.firstName}} {{requestedFollower.member.lastName}}</h2>
                  <p>{{requestedFollower.member.email}}</p>
                </ion-label>

                <!-- only show if logged in member is this member -->
                <ion-icon name="ellipsis-vertical" *ngIf="accountService.member.id === member.id"
                  (click)="requestedFollowerAction(requestedFollower);" slot="end"
                  style="align-self: end; margin-right: 0px;" color="primary">
                </ion-icon>

              </ion-item>

            </ion-item-group>

            <!-- followers -->
            <ion-item-group *ngIf="followers.length > 0" class="itemLines">

              <!-- only show the divider for the logged in member -->
              <ion-item-divider *ngIf="accountService.member.id === member.id">Following Members</ion-item-divider>

              <ion-item *ngFor="let follower of followers; let i = index;">

                <avatar #avatar [member]="follower.member" slot="start"></avatar>

                <ion-label (click)="avatar.displayProfileSummary($event)">
                  <h2>{{follower.member.firstName}} {{follower.member.lastName}}</h2>
                  <p>{{follower.member.email}}</p>
                </ion-label>

                <!-- only show if logged in member is this member -->
                <ion-icon name="ellipsis-vertical" *ngIf="accountService.member.id === member.id"
                  (click)="followerAction(follower);" slot="end" style="align-self: end; margin-right: 0px;"
                  color="primary">
                </ion-icon>

              </ion-item>

            </ion-item-group>

            <!-- blocked followers -->
            <ion-item-group *ngIf="blockedFollowers.length > 0" class="itemLines">

              <!-- only show the divider for the logged in member -->
              <ion-item-divider *ngIf="accountService.member.id === member.id">Blocked Members</ion-item-divider>

              <ion-item *ngFor="let blockedFollower of blockedFollowers; let i = index;">

                <avatar #avatar [member]="blockedFollower.member" slot="start"></avatar>

                <ion-label (click)="avatar.displayProfileSummary($event);">
                  <h2>{{blockedFollower.member.firstName}} {{blockedFollower.member.lastName}}</h2>
                  <p>{{blockedFollower.member.email}}</p>
                </ion-label>

                <!-- only show if logged in member is this member -->
                <ion-icon name="ellipsis-vertical" *ngIf="accountService.member.id === member.id"
                  (click)="blockedFollowerAction(blockedFollower);" slot="end"
                  style="align-self: end; margin-right: 0px;" color="primary">
                </ion-icon>

              </ion-item>

            </ion-item-group>

          </ion-list>

        </ng-container>

      </div>

      <!-- following -->
      <div [hidden]="profileSummarySegment !== 'following'">

        <ion-list class="itemLines">

          <!-- only allow search if this is the logged in members profile -->
          <ion-item (click)="memberSearch()" *ngIf="accountService.member.id === member.id">

            <ion-avatar style="background-color: lightgrey;" slot="start">
              <ion-icon color="primary" name="person-add" style="height: 35px; width: 35px; padding: 10px;">
              </ion-icon>
            </ion-avatar>

            <ion-label>
              <h2>Follow someone new</h2>
            </ion-label>

          </ion-item>

          <ion-item *ngFor="let follow of following; let i = index">

            <avatar #avatar [member]="follow.followedMember" slot="start"></avatar>

            <ion-label (click)="avatar.displayProfileSummary($event)">
              <h2>{{follow.followedMember.firstName}} {{follow.followedMember.lastName}}</h2>
              <p>{{follow.followedMember.email}}</p>
            </ion-label>

            <!-- only show if logged in member is this member -->
            <ion-icon name="ellipsis-vertical" *ngIf="accountService.member.id === member.id"
              (click)="unfollowMemberAction(follow);" slot="end" style="align-self: end; margin-right: 0px;"
              color="primary">
            </ion-icon>

          </ion-item>

        </ion-list>

      </div>

    </div>

    <div *ngSwitchCase="false">

      <ion-note class="message">

        <ng-container *ngIf="memberFollower.status === socialStatus.Requested, else privateAccount">
          <ion-note class="instructional" style="margin-top: 10px;"> Your request to follow this private account has
            been sent </ion-note>
        </ng-container>

      </ion-note>

    </div>

  </div>

</ion-content>

<ng-template #privateAccount>
  <ion-note class="instructional" style="margin-top: 10px;"> This account is private </ion-note>
</ng-template>

<ng-template #noPosts>
  <ion-note class="instructional" style="margin-top: 10px;"> There are no posts </ion-note>
</ng-template>

<ng-template #noFollowers>
  <ion-note class="instructional" style="margin-top: 10px;"> No members are following {{member.firstName}} </ion-note>
</ng-template>