import { Injectable } from "@angular/core";
import { environment } from '../environments/environment';
import { faTicketAlt, faThumbsUp, faThumbsDown, faTimes, faCircle, faCalendar, faUserPen, faUserCheck, faGolfBallTee } from '@fortawesome/free-solid-svg-icons';

export enum TeamSize {
    OnePersonTeam = 0,
    TwoPersonTeam = 1,
    ThreePersonTeam = 3,
    FourPersonTeam = 2
}

export enum NetGrossType {
    Net = 0,
    Gross = 1
}

export enum GameType {
    StrokePlay = 0,
    Stableford = 1,
    MatchPlay = 2,
    Skins = 3,
    Nassau = 4,
    Spins18 = 5,
    Spins9 = 6,
    Nines = 7
}

export enum ScoringType {
    OneBetter = 0,
    TwoBetter = 1,
    ThreeBetter = 2,
    FourBetter = 3
}

export enum HandicapType {
    Full = 0,
    LowBall = 1
}

export enum PressOptions {
    Off = 0,
    Prompt = 1,
    Auto = 2
}

export enum HandicapAllowance {
    Percent100 = 0,
    Percent95 = 1,
    Percent90 = 2,
    Percent85 = 3,
    Percent80 = 4,
    Percent75 = 5,
    Percent70 = 6,
    Percent65 = 7,
    Percent60 = 8,
    Percent55 = 9,
    Percent50 = 10,
}

export enum TripNotificationPreference {
    Never = 0,
    In = 1,
    Out = 2,
    InOut = 3
}

export enum EventJoinDropEmailPreference {
    Never = 0,
    Join = 1,
    Drop = 2,
    JoinDrop = 3
}

export enum EventJoinDropNotificationPreference {
    Never = 0,
    Join = 1,
    Drop = 2,
    JoinDrop = 3
}

export enum EventEmailPreference {
    Never = 0,
    First = 1,
    All = 2
}

export enum EventNotificationPreference {
    Never = 0,
    First = 1,
    All = 2
}

export enum PostNotificationPreference {
    Never = 0,
    All = 1,
    OrganizerOnly = 2
}

@Injectable({
    providedIn: 'root'
})
export class AppConfig {

    public static APP_VERSION = '2.6.2';
    public static APP_NAME = 'Double Ace Golf';
    public static BUILD_NUMBER = '116';
    public static APP_VERSION_CODE = ` (${AppConfig.BUILD_NUMBER})`;
    public static APP_STORE_ID = {
        iOS: '1545836106',
        android: 'com.doubleacegolf.app'
    }
    public static BUNDLE_ID = 'com.doubleacegolf.app'
    public static MEDIA_STORAGE = {
        AVATAR: '/avatar/',
        SOCIAL: '/social/'
    };
    public static NOT_DETERMINED: string = 'not_determined';
    public static TOUCH_ID_ACTIVE: string = 'touchId';
    public static TOUCH_ID_KEY: string = 'dag_touch_key';
    public static FIRST_TIME: string = 'firstTime';
    public static TEE_TIME_INTERVAL: number = 10;
    public static NUMBER_TEE_TIMES: number = 1;
    public static MASK = {
        phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    };
    public static REGEX = {
        email: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
    };
    public static MEMBER_SINCE_NUMBER_YEARS: number = 50;
    public static NUMBER_OF_POSTS_TO_FETCH: number = 5;
    public static EDIT_MODE = {
        new: 0,
        update: 1
    };
    //DO NOT ERASE: firebase functions:config:set sendgrid.key='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'
    public static SENDGRID_TEMPLATES = {
        EventUpdate: {
            Member: 'd-7156a96670d74084afa94fe98b642aae',
            Guest: 'd-c69c322c0af94a2386464b590b81eca4'
        },
        EventCancellation: 'd-0a33b704891944c7a136d05f53c72bea',
        GroupMessage: 'd-c4d25050c9134f478fceaa2cc9d1cb6c',
        GroupTripInvite: 'd-10e7705f62984987b50da32a8932e8f4',
        Support: 'd-ad0de65c6b554f8a9c7773778e788dd1',
        MissClubData: 'd-8d4c1dde78c14a0889ba8bbc1a633561',
        MemberDeleteConfirmation: 'd-30938a46a46648a3954549bf1687e40d',
        MemberDeleteConfirmationSupport: 'd-d1a9f33ee54d43c89838154715960a4c',
        GroupMemberAdded: 'd-eba69f0883bd466f96a42951af3b3441',
        EventMemberJoinedDropped: 'd-de2f8d77ad224798ae3631f472469438',
        NewGolfGroup: 'd-2d4498e4531342138acc5146b1fc18d0',
        NewGolfTrip: 'd-09f744b7925e4583ad557cbedf6056e7',
        NewMemberRegistration: 'd-3f208ba3cda7433785b6416f405de812'
    };
    public static NOREPLY_EMAIL = 'noreply@doubleacegolf.com';
    public static SUPPORT_EMAIL = 'support@doubleacegolf.com';
    public static COLLECTION = {
        Events: 'Events',
        EventPlayers: 'EventPlayers',
        Groups: 'Groups',
        Members: 'Members',
        Following: 'Following',
        Posts: 'Posts',
        PostComments: 'PostComments',
        PostLikes: 'PostLikes',
        Devices: 'Devices',
        PartnerContent: 'PartnerContent',
        Configuration: 'Configuration',
        Matches: 'Matches',
        MatchTeams: 'MatchTeams',
        Clubs: 'Clubs',
        Courses: 'Courses',
        Tees: 'Tees',
        Help: 'Help',
        DeepLinks: 'DeepLinks',
        Messages: 'Messages',
    };
    public static CLOUD_FUNCTIONS = {
        sendEmail: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/sendEmail',
        sendNotification: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/sendNotificationV2',
        createUserAuth: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/createUserAuth',
        createUserAuthWithPassword: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/createUserAuthWithPassword',
        deleteUserAuth: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/deleteUserAuth',
        getUserAuthProviders: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/getUserAuthProviders',
        getMemberByEmail: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/getMemberByEmail',
        revokeUserAuth: 'https://' + environment.cloudfunctions + '.cloudfunctions.net/revokeUserAuth',
        ruleHelper: "https://" + environment.cloudfunctions + ".cloudfunctions.net/ruleHelper",
        openAI: "https://" + environment.cloudfunctions + ".cloudfunctions.net/openAI",
    };
    public static WAIT_LIST_TEE_TIME: Date = new Date('12/31/9999');
    public static NO_AVATAR_URI: string = 'assets/images/avatar.png';
    public static NO_GROUP_AVATAR_URI: string = 'assets/images/user-group.png';
    public static NO_COVER_URI: string = 'assets/images/cover-background.jpeg';
    public static NO_AVATAR_URI_EMAIL: string = environment.noAvatarUriEmail;
    public static NO_TRIP_AVATAR_URI: string = 'assets/images/airplane.jpeg';
    public static NO_COVER_TRIP_URI: string = 'assets/images/cover-trip.jpeg';
    public static ANIMATION_STATE = {
        Original: 'original',
        Added: 'added'
    };
    public static UPDATE_TYPE = {
        Added: 'added',
        Modified: 'modified',
        Removed: 'removed'
    };
    public static SOCIAL_STATUS = {
        Requested: 0, //if member's account is private then following request must be requested
        Following: 1,
        Blocked: 2, //if a member is blocked from following a public account
        NotFollowing: 3
    };
    public static GROUP_PREFERENCES = {
        EVENT_EMAIL_PREFERENCE: {
            name: 'EVENT_EMAIL_PREFERENCE',
            values: [
                {
                    iconName: 'volume-mute',
                    value: EventEmailPreference.Never,
                    label: 'Never'
                },
                {
                    iconName: 'volume-low',
                    value: EventEmailPreference.First,
                    label: 'First'
                },
                {
                    iconName: 'volume-high',
                    value: EventEmailPreference.All,
                    label: 'All'
                }
            ]
        },
        EVENT_NOTIFICATION_PREFERENCE: {
            name: 'EVENT_NOTIFICATION_PREFERENCE',
            values: [
                {
                    iconName: 'volume-mute',
                    value: EventNotificationPreference.Never,
                    label: 'Never'
                },
                {
                    iconName: 'volume-low',
                    value: EventNotificationPreference.First,
                    label: 'First'
                },
                {
                    iconName: 'volume-high',
                    value: EventNotificationPreference.All,
                    label: 'All'
                }
            ]
        },
        POST_NOTIFICATION_PREFERENCE: {
            name: 'POST_NOTIFICATION_PREFERENCE',
            values: [
                {
                    iconName: 'volume-mute',
                    value: PostNotificationPreference.Never,
                    label: 'Never'
                },
                {
                    iconName: 'person',
                    value: PostNotificationPreference.OrganizerOnly,
                    label: 'Organizer Only'
                },
                {
                    iconName: 'volume-high',
                    value: PostNotificationPreference.All,
                    label: 'All'
                }
            ]
        },
        TRIP_NOTIFICATION_PREFERENCE: {
            name: 'TRIP_NOTIFICATION_PREFERENCE',
            values: [
                {
                    iconName: 'volume-mute',
                    value: TripNotificationPreference.Never,
                    label: 'Never'
                },
                {
                    iconName: 'thumbs-up',
                    value: TripNotificationPreference.In,
                    label: 'In'
                },
                {
                    iconName: 'thumbs-down',
                    value: TripNotificationPreference.Out,
                    label: 'Out'
                },
                {
                    iconName: 'volume-high',
                    value: TripNotificationPreference.InOut,
                    label: 'In/Out'
                }
            ]
        },
        EVENT_JOINDROP_EMAIL_PREFERENCE: {
            name: 'EVENT_JOINDROP_EMAIL_PREFERENCE',
            values: [
                {
                    iconName: 'volume-mute',
                    value: EventJoinDropEmailPreference.Never,
                    label: 'Never'
                },
                {
                    iconName: 'thumbs-up',
                    value: EventJoinDropEmailPreference.Join,
                    label: 'Join'
                },
                {
                    iconName: 'thumbs-down',
                    value: EventJoinDropEmailPreference.Drop,
                    label: 'Drop'
                },
                {
                    iconName: 'volume-high',
                    value: EventJoinDropEmailPreference.JoinDrop,
                    label: 'Join/Drop'
                }
            ]
        },
        EVENT_JOINDROP_NOTIFICATION_PREFERENCE: {
            name: 'EVENT_JOINDROP_NOTIFICATION_PREFERENCE',
            values: [
                {
                    iconName: 'volume-mute',
                    value: EventJoinDropNotificationPreference.Never,
                    label: 'Never'
                },
                {
                    iconName: 'thumbs-up',
                    value: EventJoinDropNotificationPreference.Join,
                    label: 'Join'
                },
                {
                    iconName: 'thumbs-down',
                    value: EventJoinDropNotificationPreference.Drop,
                    label: 'Drop'
                },
                {
                    iconName: 'volume-high',
                    value: EventJoinDropNotificationPreference.JoinDrop,
                    label: 'Join/Drop'
                }
            ]
        },
    };
    public static SUPPORT_SUBJECT = [
        {
            value: 0,
            description: 'General'
        }, {
            value: 1,
            description: 'Account'
        }, {
            value: 2,
            description: 'Club, Course or Tee'
        }, {
            value: 3,
            description: 'Live Scoring or Matches'
        }, {
            value: 4,
            description: 'Sales'
        }, {
            value: 5,
            description: 'Other'
        }
    ];
    public static HANDICAP_TYPE = {
        values: [
            {
                value: HandicapType.Full,
                name: 'Full handicap',
                description: "Players receive all their strokes based on the course handicap.",
                descriptionHeader: 'F'
            }, {
                value: HandicapType.LowBall,
                name: 'Low ball',
                description: "The player with the lowest handicap plays off zero strokes. Other players receive the difference between their handicap and the lowest handicap player's handicap.",
                descriptionHeader: 'L'
            }
        ]
    };
    public static HANDICAP_ALLOWANCE = {
        values: [
            {
                value: HandicapAllowance.Percent100,
                calcValue: 1,
                description: '100%',
                descriptionHeader: '1'
            },
            {
                value: HandicapAllowance.Percent95,
                calcValue: .95,
                description: '95%',
                descriptionHeader: '2'
            },
            {
                value: HandicapAllowance.Percent90,
                calcValue: .9,
                description: '90%',
                descriptionHeader: '3'
            },
            {
                value: HandicapAllowance.Percent85,
                calcValue: .85,
                description: '85%',
                descriptionHeader: '4'
            },
            {
                value: HandicapAllowance.Percent80,
                calcValue: .8,
                description: '80%',
                descriptionHeader: '5'
            },
            {
                value: HandicapAllowance.Percent75,
                calcValue: .75,
                description: '75%',
                descriptionHeader: '6'
            },
            {
                value: HandicapAllowance.Percent70,
                calcValue: .7,
                description: '70%',
                descriptionHeader: '7'
            }
            ,
            {
                value: HandicapAllowance.Percent65,
                calcValue: .65,
                description: '65%',
                descriptionHeader: '8'
            }
            ,
            {
                value: HandicapAllowance.Percent60,
                calcValue: .6,
                description: '60%',
                descriptionHeader: '9'
            }
            ,
            {
                value: HandicapAllowance.Percent55,
                calcValue: .55,
                description: '55%',
                descriptionHeader: '10'
            }
            ,
            {
                value: HandicapAllowance.Percent50,
                calcValue: .5,
                description: '50%',
                descriptionHeader: '11'
            }
        ]
    };
    public static NETGROSS_TYPE = {
        values: [
            {
                value: NetGrossType.Net,
                description: 'Net',
                descriptionHeader: 'N'
            },
            {
                value: NetGrossType.Gross,
                description: 'Gross',
                descriptionHeader: 'G'
            }
        ]
    };
    public static PRESS_OPTIONS: {
        values: [
            {
                iconName: 'volume-mute',
                value: PressOptions.Off,
                label: 'Off'
            },
            {
                iconName: 'volume-low',
                value: PressOptions.Prompt,
                label: 'On'
            },
            {
                iconName: 'volume-high',
                value: PressOptions.Auto,
                label: 'Auto'
            }
        ]
    };
    public static EVENT_MATCH_CONFIGURATION = {
        game: [
            { //GameType.StrokePlay
                value: GameType.StrokePlay,
                name: 'Stroke play',
                nameHeader: 'S',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Stroke play game is where low total score wins the match.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game Stroke play must have at least two (2) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Stroke play',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Stableford
                value: GameType.Stableford,
                name: 'Stableford',
                nameHeader: 'F',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Stableford game scores points based on the number of strokes taken at each hole. The player/team with the most points wins the match.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game Stableford must have at least two (2) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Stableford',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
            { //GameType.MatchPlay
                value: GameType.MatchPlay,
                name: 'Match play',
                nameHeader: 'M',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Match play game is a hole-by-hole game where the lowest score wins the hole. Tied holes do not carry over to the next hole.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game match play must have at least two (2) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Match play',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Skins
                value: GameType.Skins,
                name: 'Skins',
                nameHeader: 'K',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Skins game is a hole-by-hole game where the lowest score wins the hole. Tied holes carry over to the next hole.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game Skins must have at least two (2) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Skins',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Nassau
                value: GameType.Nassau,
                name: 'Nassau (front, back, total)',
                nameHeader: 'N',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Nassau game is three matches in one - a match on the front nine, a match on the back nine, and a match across all 18 holes.',
                allowPress: true,
                displayRules: [
                    {
                        numberOfHoles: {
                            expression: '=',
                            holes: 18
                        }
                    }
                ],
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game Nassau must have at least two (2) players.'
                        }
                    },
                    {
                        numberofTeams: {
                            number: 2,
                            validationMessage: 'The game Nassau must have at two (2) teams.'
                        }
                    },
                    {
                        holes: {
                            number: 18,
                            expression: '=',
                            validationMessage: 'The game Nassau can only be selected for eighteen (18) hole events.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Front',
                        allowPress: true,
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    },
                    {
                        name: 'Back',
                        allowPress: true,
                        holes: [9, 10, 11, 12, 13, 14, 15, 16, 17]
                    },
                    {
                        name: 'Total',
                        allowPress: false,
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Spins18
                value: GameType.Spins18,
                name: 'Spins (6/6/6)',
                nameHeader: 'S',
                description: '4 players&nbsp;&bull;&nbsp;The Spins (6/6/6, Round Robin) game is played in a foursome in which the two player teams change partners after every six holes.',
                allowPress: true,
                displayRules: [
                    {
                        numberOfHoles: {
                            expression: '=',
                            holes: 18
                        }
                    }
                ],
                validations: [
                    {
                        sameTeeTime: {
                            validationMessage: 'For the game Spins all match players must be in the same tee time.'
                        }
                    },
                    {
                        players: {
                            number: 4,
                            expression: '=',
                            validationMessage: 'The game Spins must have exactly four (4) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'First Spin',
                        allowPress: true,
                        holes: [0, 1, 2, 3, 4, 5]
                    },
                    {
                        name: 'Second Spin',
                        allowPress: true,
                        holes: [6, 7, 8, 9, 10, 11]
                    },
                    {
                        name: 'Third Spin',
                        allowPress: true,
                        holes: [12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Spins9
                value: GameType.Spins9,
                name: 'Spins (3/3/3)',
                nameHeader: 'S',
                description: '4 players&nbsp;&bull;&nbsp;The Spins (3/3/3, Round Robin) game is played in a foursome in which the two player teams change partners after every three holes.',
                allowPress: true,
                displayRules: [
                    {
                        numberOfHoles: {
                            expression: '=',
                            holes: 9
                        }
                    }
                ],
                validations: [
                    {
                        sameTeeTime: {
                            validationMessage: 'For the game Spins all match players must be in the same tee time.'
                        }
                    },
                    {
                        players: {
                            number: 4,
                            expression: '=',
                            validationMessage: 'The game Spins must have exactly four (4) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'First Spin',
                        allowPress: true,
                        holes: [0, 1, 2]
                    },
                    {
                        name: 'Second Spin',
                        allowPress: true,
                        holes: [3, 4, 5]
                    },
                    {
                        name: 'Third Spin',
                        allowPress: true,
                        holes: [6, 7, 8]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Nines
                value: GameType.Nines,
                name: 'Nines',
                nameHeader: 'N',
                description: 'Exactly 3 players&nbsp;&bull;&nbsp;The Nines game is where three players compete for points on each hole.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 3,
                            expression: '=',
                            validationMessage: 'The game Nines must have exactly three (3) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Event Match',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    }
                ]
            }
        ]
    };
    public static TRIP_MATCH_CONFIGURATION = {
        game: [
            { //GameType.StrokePlay
                value: GameType.StrokePlay,
                name: 'Stroke play',
                nameHeader: 'S',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Stroke play game is where low total score wins the match.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game Stroke play must have at least two (2) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Stroke play',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
            { //GameType.Stableford
                value: GameType.Stableford,
                name: 'Stableford',
                nameHeader: 'F',
                description: '2 to 4 players or teams&nbsp;&bull;&nbsp;The Stableford game scores points based on the number of strokes taken at each hole. The player/team with the most points wins the match.',
                allowPress: false,
                validations: [
                    {
                        players: {
                            number: 2,
                            expression: '>=',
                            validationMessage: 'The game Stableford must have at least two (2) players.'
                        }
                    }
                ],
                matches: [
                    {
                        name: 'Stableford',
                        holes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                    }
                ],
                teamSize: [
                    {
                        value: TeamSize.OnePersonTeam,
                        description: 'One person team',
                        descriptionHeader: '1',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            }
                        ]
                    },
                    {
                        value: TeamSize.TwoPersonTeam,
                        description: 'Two person team',
                        descriptionHeader: '2',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            }
                        ]
                    },
                    {
                        value: TeamSize.ThreePersonTeam,
                        description: 'Three person team',
                        descriptionHeader: '3',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            }
                        ]
                    },
                    {
                        value: TeamSize.FourPersonTeam,
                        description: 'Four person team',
                        descriptionHeader: '4',
                        scoringType: [
                            {
                                value: ScoringType.OneBetter,
                                description: 'One better ball',
                                descriptionHeader: '1'
                            },
                            {
                                value: ScoringType.TwoBetter,
                                description: 'Two better ball',
                                descriptionHeader: '2'
                            },
                            {
                                value: ScoringType.ThreeBetter,
                                description: 'Three better ball',
                                descriptionHeader: '3'
                            },
                            {
                                value: ScoringType.FourBetter,
                                description: 'Four better ball',
                                descriptionHeader: '4'
                            }
                        ]
                    }
                ]
            },
        ]
    };
    public static SPINNER = 'assets/images/spinner.gif';
    public static APP_RATE = 'apprate';
    public static APP_RATE_PROMPT_COUNT = 3;
    public static DEFAULT_HANDICAP_INDEX = 15;
    public static faTicketAlt = faTicketAlt;
    public static faThumbsUp = faThumbsUp;
    public static faThumbsDown = faThumbsDown;
    public static faTimes = faTimes;
    public static faCircle = faCircle;
    public static faCalendar = faCalendar;
    public static faUserPen = faUserPen;
    public static faGolfBallTee = faGolfBallTee;
    public static faUserCheck = faUserCheck;
    public static PAGE = {
        GroupTripView: 'group-trip-view',
        GroupTripDetail: 'group-trip-detail',
        GroupEventView: 'group-event-view',
        GroupEventDetail: 'group-event-detail',
        GroupList: 'group-list',
        EventView: 'event-view',
        EventDetail: 'event-detail',
        RuleHelper: 'rule-helper',
        Home: 'home'
    };
    public static POSTHOG_EVENTS = {
        AppLaunch: 'AppLaunch',
    }
}
